import React from 'react'
import styled, { keyframes } from 'styled-components'

import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import ObjectCard from './ObjectCard'

const Component = styled.div`
`

const Content = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15vw;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }

  &::after {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15vw;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`

const scroll = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-25%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
`

const Cards = styled.div`
  display: flex;
  animation: ${scroll} 35s linear infinite;
`

const Card = styled.div`
  width: 150px;
  height: 150px;
  flex-shrink: 0;

  ${MEDIA_QUERY_DESKTOP} {
    width: 420px;
    height: 340px;
  }

  &:last-child {
    margin-right: 0;
  }
`

const Carousel = ({ cards }) => {
  return (
    <Component>
      <Content>
        <Cards>
          {cards.map((c, i) => {
            return (
              <Card key={i}>
                <ObjectCard
                  src={c.src}
                  name={c.name}
                />
              </Card>
            )
          })}
        </Cards>
      </Content>
    </Component>
  )
}

export default Carousel
