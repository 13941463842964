import React from 'react'
import { Linkedin, GitHub, Instagram, Twitter, Youtube } from 'react-feather'
import styled from 'styled-components'

const SCALE = 1.0
const BUTTON_SIZE = 24 * SCALE

export const SOCIAL_PLATFORM_TWITTER = 'SOCIAL_PLATFORM_TWITTER'
export const SOCIAL_PLATFORM_IG = 'SOCIAL_PLATFORM_IG'
export const SOCIAL_PLATFORM_GITHUB = 'SOCIAL_PLATFORM_GITHUB'
export const SOCIAL_PLATFORM_LINKEDIN = 'SOCIAL_PLATFORM_LINKEDIN'
export const SOCIAL_PLATFORM_YT = 'SOCIAL_PLATFORM_YT'

const Component = styled.div`
  display: flex;
`

const Button = styled.a`
  background-color: white;
  color: black;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: ${BUTTON_SIZE / 2}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  opacity: .25;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }
`

const getSocialIcon = socialPlatform => {
  switch (socialPlatform) {
    case SOCIAL_PLATFORM_GITHUB:
      return GitHub
    case SOCIAL_PLATFORM_LINKEDIN:
      return Linkedin
    case SOCIAL_PLATFORM_TWITTER:
      return Twitter
    case SOCIAL_PLATFORM_IG:
      return Instagram
    case SOCIAL_PLATFORM_YT:
      return Youtube
  }
}

const TeamMemberSocialButtons = ({ socials }) => {
  return (
    <Component>
      {socials.map((social, index) => {
        const SocialIcon = getSocialIcon(social.platform)

        return (
          <Button
            key={index}
            rel='noreferrer'
            target='_blank'
            href={social.href}
          >
            <SocialIcon size={16 * SCALE} />
          </Button>
        )
      })}
    </Component>
  )
}

export default TeamMemberSocialButtons
