import React from 'react'
import styled from 'styled-components'

import Container from '../../components/Container'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { Section, SPACE_L, SPACE_M, SubTitle, TitleOne, TitleTwo } from './styled'
import TeamMemberSocialButtons, {
  SOCIAL_PLATFORM_GITHUB, SOCIAL_PLATFORM_IG,
  SOCIAL_PLATFORM_LINKEDIN,
  SOCIAL_PLATFORM_TWITTER, SOCIAL_PLATFORM_YT
} from './TeamMemberSocialButtons'

const coreTeam = [{
  name: 'mikev2',
  title: 'Founder, Dev, Design',
  imgSrc: '/images/team/mike.jpg',
  description: (
    <>
      Decision maker, 10+ years of software dev and UX/UI experience. Worked for Sony, The Orchard, CleverTech.
    </>
  ),
  socials: [{
    platform: SOCIAL_PLATFORM_LINKEDIN,
    href: 'https://www.linkedin.com/in/mike-vercoelen'
  }, {
    platform: SOCIAL_PLATFORM_GITHUB,
    href: 'https://github.com/mikevercoelen'
  }]
}, {
  name: 'vindo',
  title: 'Founder, Marketing',
  imgSrc: '/images/team/vince.jpg',
  description: (
    <>
      Digital marketing since 2017. Highly experienced with brand & concept development.
    </>
  ),
  socials: [{
    platform: SOCIAL_PLATFORM_LINKEDIN,
    href: 'https://www.linkedin.com/in/vince-smolders/'
  }]
}, {
  name: 'yurchax',
  title: 'Marketing',
  imgSrc: '/images/team/arthur.jpg',
  description: (
    <>
      Internet marketing multi-tool. Involved in blockchain space since 2016. Recent music theory nerd.
    </>
  ),
  socials: [{
    platform: SOCIAL_PLATFORM_LINKEDIN,
    href: 'https://www.linkedin.com/in/jurcaks/'
  }]
}, {
  name: 'Otgaard',
  title: '2D, 3D Dev',
  imgSrc: '/images/team/darren.jpg',
  description: (
    <>
      The brain behind VOID's visual rendering system. Graphics dev with 15+ years of experience.
    </>
  ),
  socials: [{
    platform: SOCIAL_PLATFORM_GITHUB,
    href: 'https://github.com/otgaard'
  }]
}]

const associates = [{
  name: 'xBenJamminx',
  imgSrc: '/images/team-avatars/xben.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_TWITTER,
    href: 'https://twitter.com/xBenJamminx'
  }]
}, {
  name: 'ClaudeVonStroke',
  imgSrc: '/images/team-avatars/claude.jpg',
  socials: [{
    platform: SOCIAL_PLATFORM_TWITTER,
    href: 'https://twitter.com/VonStroke'
  }]
}, {
  name: 'Gui Boratto',
  imgSrc: '/images/team-avatars/guib.webp',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/guiboratto'
  }]
}, {
  name: 'Marc Romboy',
  imgSrc: '/images/team-avatars/marcr.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/marcromboy'
  }]
}, {
  name: 'Robert Babicz',
  imgSrc: '/images/team-avatars/robert.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/robertbabicz'
  }]
}, {
  name: 'L_cio',
  imgSrc: '/images/team-avatars/l_cio.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/l_cio'
  }]
}, {
  name: 'Coppola',
  imgSrc: '/images/team-avatars/coppola.jpg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/coppoladj/'
  }]
}, {
  name: 'Paradigitz',
  imgSrc: '/images/team-avatars/paradigitz.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/flip_paradigitz/'
  }]
}, {
  name: 'ELPHNT',
  imgSrc: '/images/team-avatars/elphnt.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_YT,
    href: 'https://www.youtube.com/c/ELPHNT'
  }]
}, {
  name: 'SYYN',
  imgSrc: '/images/team-avatars/alex.jpeg',
  socials: [{
    platform: SOCIAL_PLATFORM_IG,
    href: 'https://www.instagram.com/sy____in/'
  }]
}]

const Members = styled.div`
  margin-top: ${SPACE_M};
  display: grid;
  grid-row-gap: 2rem;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: ${SPACE_L};
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    grid-row-gap: 4rem;
  }
`

const Member = styled.div`
  width: 100%;
`

const IMG_SIZE = 175
const IMG_SCALE_MOBILE = 0.5

const MemberImage = styled.img`
  margin-bottom: 1rem;
  width: ${IMG_SIZE * IMG_SCALE_MOBILE}px;
  height: ${IMG_SIZE * IMG_SCALE_MOBILE}px;
  border-radius: ${(IMG_SIZE * IMG_SCALE_MOBILE) / 2}px;

  ${MEDIA_QUERY_DESKTOP} {
    width: ${IMG_SIZE}px;
    border-radius: ${IMG_SIZE / 2}px;
    height: ${IMG_SIZE}px;
  }
`

const MemberName = styled(TitleTwo)`
  font-size: 14px;

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 22px;
  }
`

const MemberTitle = styled(SubTitle)`
  margin: .25rem 0;
  font-size: 10px;

  ${MEDIA_QUERY_DESKTOP} {
    margin: 1rem 0;
    font-size: 14px;
  }
`

const MemberDescription = styled.div`
  font-size: 14px;
  opacity: .85;
  text-align: center;
  margin-bottom: 1rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: 0;
    min-height: 110px;
  }
`

const Title = styled(TitleOne)`
  ${MEDIA_QUERY_DESKTOP} {
    max-width: 671px;
  }
`

const TitlePartners = styled(TitleOne)`
  margin-top: 2rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: 6rem;
  }
`

const MemberSocialLinks = styled.div`
  display: flex;
  justify-content: center;
`

interface MembersListProps {
  members: any[]
}

const MembersList = ({ members }: MembersListProps) => {
  return (
    <Members>
      {members.map((member, index) => {
        return (
          <Member key={index}>
            <MemberImage
              src={member.imgSrc}
              alt={member.name}
            />
            <MemberName>
              {member.name}
            </MemberName>
            <MemberTitle>
              {member.title}
            </MemberTitle>
            {member.description && (
              <MemberDescription>
                {member.description}
              </MemberDescription>
            )}
            <MemberSocialLinks>
              <TeamMemberSocialButtons socials={member?.socials || []} />
            </MemberSocialLinks>
          </Member>
        )
      })}
    </Members>
  )
}

const Team = () => {
  return (
    <Container>
      <Section>
        <Title>
          Meet the Triptcip team
        </Title>
        <MembersList members={coreTeam} />
        <TitlePartners>
          Our Friends
        </TitlePartners>
        <MembersList members={associates} />
      </Section>
    </Container>
  )
}

export default Team
