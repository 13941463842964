import React from 'react'
import styled from 'styled-components'

import Container from '../../components/Container'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { Paragraph, TitleOne, TitleOutline } from './styled'

const Component = styled.div`
  height: 75vh;
  width: 100%;
  background: url('/images/gui-boratto.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;

  ${MEDIA_QUERY_DESKTOP} {
    height: 95vh;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .72);
  }
`

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  bottom: 2rem;
  text-align: center;

  ${TitleOne} {
    line-height: 1;
  }

  ${Paragraph} {
    margin-top: 1rem;

    ${MEDIA_QUERY_DESKTOP} {
      margin-top: 2rem;
    }
  }

  ${MEDIA_QUERY_DESKTOP} {
    bottom: 8rem;
    text-align: left;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${MEDIA_QUERY_DESKTOP} {
    text-align: left;
  }
`

const GuiBoratto = () => {
  return (
    <Component>
      <Content>
        <Container>
          <TitleContainer>
            <TitleOutline>
              Audio by
            </TitleOutline>
            <TitleOne>
              Gui Boratto
            </TitleOne>
          </TitleContainer>
          <Paragraph>
            The one and only
            {' '}
            <a
              href='https://www.instagram.com/guiboratto/?hl=en'
              rel='noreferrer'
              target='_blank'
            >
              Gui Boratto
            </a>
            {' '}
            has produced the audio for Objects.
            There are over 200 randomly distributed loops. The genres vary from techno to hiphop, from drum and bass to ambient.
            Most loops were created using the legendary
            {' '}
            <a
              href='https://www.youtube.com/watch?v=ZuFpkQd1yVc'
              rel='noreferrer'
              target='_blank'
            >
              Buchla
            </a>
            {' '}
            synthesizer.
          </Paragraph>
        </Container>
      </Content>
    </Component>
  )
}

export default GuiBoratto
