import React from 'react'
import styled from 'styled-components'

import { FONT_FAMILY_ALT_OUTLINE } from '../../theme'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'

const Component = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Preview = styled.div`
`

const Content = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const Footer = styled.div`
  z-index: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;

  ${MEDIA_QUERY_DESKTOP} {
    display: flex;
  }
`

const Name = styled.div`
  text-transform: uppercase;
  font-weight: 800;
  padding: 1rem;
  font-family: ${FONT_FAMILY_ALT_OUTLINE};
  line-height: 1;
  font-size: 24px;
`

const ObjectCard = ({ name, src }) => {
  return (
    <Component>
      <Preview>
        <Content>
          <Image
            src={src}
            width='100%'
            height='100%'
          />
        </Content>
      </Preview>
      <Footer>
        <Name>
          {name}
        </Name>
      </Footer>
    </Component>
  )
}

export default ObjectCard
