import React from 'react'
import styled from 'styled-components'

import { HEADER_HEIGHT } from '../../components/NewPageHeader/NewPageHeader'
import Discord from './Discord'
import GuiBoratto from './GuiBoratto'
import Hero from './Hero'
import Legendaries from './Legendaries'
import Roadmap from './Roadmap'
import SubHero from './SubHero'
import Team from './Team'
import Traits from './Traits'
import Void from './Void'

const Component = styled.div`
  margin-top: -${HEADER_HEIGHT}px;
`

const ObjectsNew = () => {
  return (
    <Component>
      <Hero />
      <Void />
      <SubHero />
      <Traits />
      <GuiBoratto />
      <Roadmap />
      <Legendaries />
      <Team />
      <Discord />
    </Component>
  )
}

export default ObjectsNew
