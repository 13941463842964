import React from 'react'
import styled from 'styled-components'

import { MOBILE_SIDE_PADDING } from '../../theme'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { Paragraph, TitleOneSmall } from './styled'

const Component = styled.div`
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 0 ${MOBILE_SIDE_PADDING};

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: 2.25rem;
  }
`

const Sections = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  ${MEDIA_QUERY_DESKTOP} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
`

const SectionFirst = styled(Paragraph)`
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 1rem;
  margin-bottom: 1rem;

  ${MEDIA_QUERY_DESKTOP} {
    text-align: justify;
    padding: 0 2rem 0 0;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 250px;

  ${MEDIA_QUERY_DESKTOP} {
    padding: 2rem;
  }
`

const SectionTitle = styled(TitleOneSmall)`
  font-size: 16px;
  line-height: 1.4;

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 19px;
  }
`

const SectionContent = styled.div`
`

const sections = [{
  title: 'Music sync & sequencing',
  description: (
    <>
      Sequence your synthesized visuals to your own music.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-4.png'
}, {
  title: 'Visual Synthesis',
  description: (
    <>
      Create complex visuals and animate or link frequency bands to over 400+ shader parameters.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-1.png'
}, {
  title: 'Image, video & 3d import',
  description: (
    <>
      Every month we release one, or multiple features for increased creativity.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-5.png'
}, {
  title: 'Presets & Effects',
  description: (
    <>
      Import your own artwork, images, videos, 3d models.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-2.png'
}, {
  title: 'Advanced 3D engine',
  description: (
    <>
      Plug-in your own artwork into our 3D engine, or use built-in 3D shapes to create complex visual renderers.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-6.png'
}, {
  title: 'Macros & MIDI Input',
  description: (
    <>
      Directly create NFTs with <span>VOID</span> or export your work in 4k. All from the browser.
    </>
  ),
  imgSrc: '/images/void-tiles/void-tile-7.png'
}]

const TILE_IMAGE_OVERLAY_OPACITY = 0.1

const VoidTiles = () => {
  return (
    <Component>
      <Sections>
        <SectionFirst>
          An Object gives you lifetime access to <span>VOID</span>, our software that merges the worlds of music and generative art. Use your <span>VOID</span> creations for music videos, DJ sets, NFTs, or as a unique visual identity for your sound.
          <br />
          <br />
          Built using WebGL2, <span>VOID</span> features a custom rendering engine with over 50 modules and 600 parameters that can be automated and mapped to audio frequency bands.
        </SectionFirst>
        {sections.map((s, i) => {
          const style = {}

          if (s.imgSrc) {
            style.backgroundImage = `linear-gradient(90deg, rgba(0, 0, 0, ${TILE_IMAGE_OVERLAY_OPACITY}), rgba(0, 0, 0, ${TILE_IMAGE_OVERLAY_OPACITY})), url(${s.imgSrc})`
          }

          if (s.isFaded) {
            style.opacity = 0.5
          }

          return (
            <Section
              style={style}
              key={i}
            >
              <SectionContent>
                <SectionTitle>
                  {s.title}
                </SectionTitle>
              </SectionContent>
            </Section>
          )
        })}
      </Sections>
    </Component>
  )
}

export default VoidTiles
