import Link from 'next/link'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import { URL_DISCORD } from '../../config'
import { OBJECTS_MINT } from '../../constants/Routes'
import { FONT_FAMILY_ALT } from '../../theme'
import { MEDIA_QUERY_DESKTOP, MEDIA_QUERY_DESKTOP_WIDE, MEDIA_QUERY_TABLET } from '../../utils/media-query'
import { ButtonPrimary, TitleFour } from './styled'

const Component = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Content = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 5;
  text-align: center;
  padding: 10px;
`

const Title = styled.h1`
  font-family: ${FONT_FAMILY_ALT};
  font-size: 42px;
  letter-spacing: 0;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;

  ${MEDIA_QUERY_TABLET} {
    font-size: 80px;
    letter-spacing: -0.045em;
    margin-bottom: 1rem;
  }

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 100px;
    letter-spacing: -0.045em;
  }

  ${MEDIA_QUERY_DESKTOP_WIDE} {
    font-size: 120px;
    letter-spacing: -0.045em;
  }
`

const Subtitle = styled(TitleFour)`
  margin-bottom: 2rem;

  span {
    font-family: ${FONT_FAMILY_ALT};
    text-transform: uppercase;
    font-weight: 800;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 102%;
    background: black;
    opacity: .5;
  }
`

const BackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
`

const Footer = styled.div``

const FooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERY_DESKTOP} {
    display: flex;
    flex-direction: row;
  }

  button {
    margin-right: 1rem;
    margin-bottom: .5rem;

    ${MEDIA_QUERY_DESKTOP} {
      margin-bottom: 4px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

const FooterButtonLink = styled.a`
  margin-right: .5rem;
  display: flex;
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: none;
    color: black;
  }
`

const ButtonJoin = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  font-family: ${FONT_FAMILY_ALT};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  color: black;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 20px;
  }

  ${MEDIA_QUERY_DESKTOP_WIDE} {
    font-size: 24px;
  }
`

const VIDEO_URI = isMobile
  ? '/video/OBJECTS_BACKGROUND_NEW_SHORT_Q35.mp4'
  : '/video/OBJECTS_BACKGROUND_NEW_SHORT_Q30.mp4'

const Hero = () => {
  return (
    <Component>
      <Background>
        <BackgroundVideo
          src={VIDEO_URI}
          role='presentation'
          crossOrigin='anonymous'
          playsInline
          autoPlay
          preload='auto'
          muted
          loop
          width='100%'
          height='100%'
        />
      </Background>
      <Content>
        <Title>
          Objects
        </Title>
        <Subtitle>
          Your access to <span>VOID</span>, our state of the art visual software suite
        </Subtitle>
        <Footer>
          <FooterButtons>
            <Link
              href={OBJECTS_MINT}
              rel='noreferrer'
              target='_blank'
              passHref
            >
              <ButtonPrimary>
                Mint Your Object
              </ButtonPrimary>
            </Link>
            <FooterButtonLink
              href={URL_DISCORD}
              rel='noreferrer'
              target='_blank'
            >
              <ButtonJoin>
                Join the community
              </ButtonJoin>
            </FooterButtonLink>
          </FooterButtons>
        </Footer>
      </Content>
    </Component>
  )
}

export default Hero
