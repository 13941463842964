import { Spinner } from '@triptcip/common'
import React from 'react'
import styled from 'styled-components'

import { MIN_HEIGHT, PLACEHOLDER_PADDING_TOP } from './constants'

const Component = styled.div`
  width: 100%;
  padding-top: ${PLACEHOLDER_PADDING_TOP}%;
  position: relative;
  min-height: ${MIN_HEIGHT}px;
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/trait-previewer-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const Placeholder = () => {
  return (
    <Component>
      <Inner>
        <Spinner
          variant={Spinner.VARIANT_PRIMARY}
          size={42}
        />
      </Inner>
    </Component>
  )
}

export default Placeholder
