import styled from 'styled-components'

import { FONT_FAMILY_ALT, FONT_FAMILY_ALT_OUTLINE, COLOR_OBJECTS } from '../../theme'
import { MEDIA_QUERY_DESKTOP, MEDIA_QUERY_DESKTOP_WIDE, MEDIA_QUERY_TABLET } from '../../utils/media-query'

export const SPACE_S = '1rem'
export const SPACE_M = '2rem'
export const SPACE_L = '6rem'

export const TitleOne = styled.h3`
  font-size: 24px;
  font-family: ${FONT_FAMILY_ALT};
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2;

  ${MEDIA_QUERY_TABLET} {
    font-size: 42px;
  }

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 58px;
  }

  ${MEDIA_QUERY_DESKTOP_WIDE} {
    font-size: 64px;
  }
`

export const TitleOneSmall = styled(TitleOne)`
  font-size: 24px;

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 34px;
  }
`

export const TitleOutline = styled(TitleOne)`
  font-family: ${FONT_FAMILY_ALT_OUTLINE};
`

export const TitleTwo = styled.h2`
  font-family: ${FONT_FAMILY_ALT};
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 28px;
  }
`

export const SubTitle = styled.h4`
  font-family: ${FONT_FAMILY_ALT};
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
`

export const TitleFour = styled.h3`
  font-weight: 300;
  font-size: 24px;
  margin-bottom: ${SPACE_S};

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 36px;
    line-height: 1.3;
  }
`

export const TitleThree = styled.h3`
  font-weight: 400;
  font-size: 18px;
  margin-bottom: ${SPACE_S};
`

export const Paragraph = styled.p`
  font-size: 16px;
  opacity: .85;

  ${MEDIA_QUERY_TABLET} {
    font-size: 18px;
    max-width: 730px;
  }

  span {
    font-family: ${FONT_FAMILY_ALT};
    text-transform: uppercase;
    font-weight: 800;
  }

  mark {
    background-color: ${COLOR_OBJECTS};
    color: white;
    font-weight: 700;
  }
`

export const ParagraphSubtext = styled(Paragraph)`
  opacity: .5;
`

export const Section = styled.div`
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${MEDIA_QUERY_DESKTOP} {
    padding: ${SPACE_L} 0;
  }
`

export const ButtonPrimary = styled.button`
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
  background: ${COLOR_OBJECTS};
  font-family: ${FONT_FAMILY_ALT};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  color: white;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 20px;
  }

  ${MEDIA_QUERY_DESKTOP_WIDE} {
    font-size: 24px;
  }
`

export const ButtonBorder = styled.button`
  border-radius: 0;
  -webkit-appearance: none;
  border: 3px solid white;
  background: none transparent;
  font-family: ${FONT_FAMILY_ALT};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  color: white;

  :disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  ${MEDIA_QUERY_DESKTOP} {
    font-size: 20px;
  }

  ${MEDIA_QUERY_DESKTOP_WIDE} {
    font-size: 24px;
  }
`
