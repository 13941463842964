import React from 'react'
import styled from 'styled-components'

import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { SPACE_L } from './styled'

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin-top: 4rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: ${SPACE_L};
    grid-template-columns: repeat(3, 1fr);
  }
`

const Legendary = styled.img`
  width: 100%;
  object-fit: cover;

  &:last-child {
    display: none;

    ${MEDIA_QUERY_DESKTOP} {
      display: block;
    }
  }
`

const legendaries = [{
  src: '/images/carousel-static/legendary-1.jpg'
}, {
  src: '/images/carousel-static/legendary-2.jpg'
}, {
  src: '/images/carousel-static/legendary-3.jpg'
}]

const Legendaries = () => {
  return (
    <Component>
      {legendaries.map((l, i) => {
        return (
          <Legendary
            key={i}
            src={l.src}
          />
        )
      })}
    </Component>
  )
}

export default Legendaries
