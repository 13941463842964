import dynamic from 'next/dynamic'
import React from 'react'

import Placeholder from './Placeholder'

export default dynamic(() => import('./TraitPreviewer'), {
  ssr: false,
  loading: () => {
    return (
      <Placeholder />
    )
  }
})
