import React from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'

import Container from '../../components/Container'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { Paragraph, Section, SPACE_M, TitleFour, TitleOne, TitleTwo } from './styled'
import TraitPreviewer from './TraitPreviewer'
import Placeholder from './TraitPreviewer/Placeholder'

const Component = styled.div`
  ${TitleTwo} {
    margin-bottom: ${SPACE_M};
  }
`

const TraitPreviewerContainer = styled.div`
  padding-bottom: 6rem;
`

const Title = styled(TitleOne)`
  line-height: 1;
  margin-bottom: 0;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: .25rem;
  }
`

const TitleSecondary = styled(TitleFour)`
  margin-top: .75rem;
  margin-bottom: .5rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
`

const Traits = () => {
  return (
    <Component>
      <Container>
        <Section>
          <Title>
            Traits
          </Title>
          <Paragraph>
            There are 400 textures, 10 morphing shapes and 8 effects.
            The rarity tier can be: <mark>Common</mark>, <mark>Rare</mark>, and <mark>Legendary</mark>.
            If an Object has an effect it's Rare, otherwise its Common.
            There are 40 handmade Legendary objects.
          </Paragraph>
        </Section>
        <TraitPreviewerContainer>
          <LazyLoad
            unmountIfInvisible
            debounce
            placeholder={(
              <Placeholder />
            )}
          >
            <TraitPreviewer />
          </LazyLoad>
        </TraitPreviewerContainer>
      </Container>
    </Component>
  )
}

export default Traits
