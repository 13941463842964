import React from 'react'
import styled from 'styled-components'

import Container from '../../components/Container'
import Carousel from './Carousel'
import { Paragraph, Section, TitleOne } from './styled'

const Title = styled(TitleOne)`
  margin-bottom: 1rem;
`

const carouselCards = [
  {
    src: '/images/carousel-static/4.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/3.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/7.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/2.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/5.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/1.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/6.jpg',
    name: 'Common'
  },
  {
    src: '/images/carousel-static/23.jpg',
    name: 'Common'
  }
]

const Description = styled(Paragraph)`
  max-width: 770px;
`

const SubHero = () => {
  return (
    <>
      <Container>
        <Section>
          <Title>
            About Objects
          </Title>
          <Description>
            Visually, Objects are real-time rendered NFTs made with VOID.
            They respond to audio frequencies and use your GPU to render shader code, geometry, lightning and reflections.
          </Description>
        </Section>
      </Container>
      <Carousel cards={carouselCards} />
    </>
  )
}

export default SubHero
