import React from 'react'
import styled from 'styled-components'

import Container from '../../components/Container'
import IconCollab from '../../icons/Collab.svg'
import IconLive from '../../icons/live.svg'
import IconMarket from '../../icons/market.svg'
import IconSynth from '../../icons/synth.svg'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { Paragraph, ParagraphSubtext, Section, SPACE_L, SPACE_M, TitleOne, TitleOneSmall } from './styled'

const sections = [{
  title: (
    <>
      Creator
      <br />
      Platform
    </>
  ),
  date: 'Q2 2022',
  description: (
    <>
      Collaborate with other artists to mint your <span>VOID</span> creations
      on our upcoming marketplace and split automatic royalties.
    </>
  ),
  icon: (
    <IconMarket />
  )
}, {
  title: (
    <>
      Collaborative Drops
    </>
  ),
  date: 'Q1 2022',
  description: (
    <>
      <span>VOID</span> will be used in partnerships with labels, guest artists, and NFT projects to launch audiovisual NFT collections.
    </>
  ),
  icon: (
    <IconCollab />
  )
}, {
  title: (
    <>
      VOID - Live
    </>
  ),
  date: 'Q2 2022',
  description: (
    <>
      <span>VOID</span> for clubs, festivals and livestreams.
      <br />
      <strong>LIVE mode</strong> will bring support for MIDI controllers, Ableton integration, and multi-screen rendering.
    </>
  ),
  icon: (
    <IconLive />
  )
}, {
  title: (
    <>
      VOID - Audio Synth
    </>
  ),
  date: 'Q3 2022',
  description: (
    <>
      A modular audio synthesizer with sequencing will be added to <span>VOID</span>. Allowing <span>VOID</span> creators to create full audiovisual experiences.
    </>
  ),
  icon: (
    <IconSynth />
  )
}]

const RoadmapSections = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: ${SPACE_M};

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: ${SPACE_L};
    grid-gap: ${SPACE_L};
    grid-template-columns: repeat(2, 1fr);
  }
`

const RoadmapSectionIcon = styled.div`
  margin-bottom: 1rem;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: 2rem;
    width: 115px;
    height: 115px;
  }
`

const RoadmapSection = styled.div`
  border: 2px solid white;
  padding: ${SPACE_M};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &:nth-child(2) {
    ${RoadmapSectionIcon} {
      svg {
        width: 95%;
        height: 95%;
      }
    }
  }

  &:nth-child(3) {
    ${RoadmapSectionIcon} {
      svg {
        width: 110%;
        height: 110%;
      }
    }
  }

  &:nth-child(4) {
    ${RoadmapSectionIcon} {
      svg {
        width: 90%;
        height: 90%;
      }
    }
  }
`

const RoadmapSectionTitle = styled(TitleOneSmall)`
`

const RoadmapSectionDate = styled(ParagraphSubtext)`
  padding: 1rem 0;
`

const FootNote = styled(Paragraph)`
  text-align: center;
  color: rgba(255, 255, 255, .5);
  max-width: none;
`

const Metaverse = styled.div`
  margin-top: 5rem;
  padding-bottom: 2rem;

  ${MEDIA_QUERY_DESKTOP} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 4rem;
  }
`

const MetaverseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MetaverseTitle = styled(TitleOneSmall)`
  margin-bottom: 1rem;
  text-align: center;

  ${MEDIA_QUERY_DESKTOP} {
    text-align: left;
    margin-bottom: 2rem;
  }
`

const MetaverseDescription = styled(Paragraph)`
  text-align: justify;
  margin-bottom: 2rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: 0;
  }
`

const MetaverseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`

const RoadmapSectionDescription = styled(Paragraph)``

const Roadmap = () => {
  return (
    <Container>
      <Section>
        <TitleOne>
          Roadmap
        </TitleOne>
      </Section>
      <RoadmapSections>
        {sections.map((s, i) => {
          return (
            <RoadmapSection key={i}>
              <RoadmapSectionIcon>
                {s.icon}
              </RoadmapSectionIcon>
              <RoadmapSectionTitle>
                {s.title}
              </RoadmapSectionTitle>
              <RoadmapSectionDate>
                {s.date}
              </RoadmapSectionDate>
              <RoadmapSectionDescription>
                {s.description}
              </RoadmapSectionDescription>
            </RoadmapSection>
          )
        })}
      </RoadmapSections>
      <FootNote>
        Besides the milestones above, we're always working on new <span>VOID</span> features and improvements.
      </FootNote>
      <Metaverse>
        <MetaverseInfo>
          <MetaverseTitle>
            Metaverse awaits
          </MetaverseTitle>
          <MetaverseDescription>
            We believe Metaverse will play a big part in the future of music & entertainment, and we’re here to contribute to it.
            Currently, we’re exploring opportunities to integrate <span>VOID</span> in <mark>virtual live performances</mark> and are excited to be a part of this new experience for music artists and their fans.
          </MetaverseDescription>
        </MetaverseInfo>
        <MetaverseImage
          src='/images/metaverse.jpg'
        />
      </Metaverse>
    </Container>
  )
}

export default Roadmap
