import React from 'react'
import styled from 'styled-components'

import { URL_DISCORD } from '../../config'
import { COLOR_OBJECTS } from '../../theme'
import { ButtonBorder, TitleOne } from './styled'

const Component = styled.div`
  padding: 12rem 0;
  background-color: ${COLOR_OBJECTS};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const Title = styled(TitleOne)`
  margin-bottom: 2rem;
  max-width: 950px;
`

const Discord = () => {
  return (
    <Component>
      <Title>
        Ready to join a new wave of art?
      </Title>
      <a
        href={URL_DISCORD}
        rel='noreferrer'
        target='_blank'
      >
        <ButtonBorder>
          Join the community
        </ButtonBorder>
      </a>
    </Component>
  )
}

export default Discord
