import Link from 'next/link'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import Container from '../../components/Container'
import { PROJECTS_CREATE_SCENE } from '../../constants/Routes'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import { ButtonPrimary, Paragraph, Section, SPACE_M, TitleFour, TitleOne } from './styled'
import VoidTiles from './VoidTiles'

const Title = styled(TitleOne)`
  margin-bottom: 0;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: 1rem;
  }
`

const Footer = styled.div`
  margin-top: ${SPACE_M};

  ${MEDIA_QUERY_DESKTOP} {
    margin-top: 4rem;
  }

  button {
    font-size: 14px;

    ${MEDIA_QUERY_DESKTOP} {
      font-size: 16px;
    }
  }
`

const FootNote = styled(Paragraph)`
  font-size: 16px;
  opacity: .5;
  margin-top: 2rem;
`

const VideoContainer = styled.div`
  width: 100%;
  height: 35vh;
  position: relative;

  ${MEDIA_QUERY_DESKTOP} {
    height: 75vh;
    margin-bottom: 10vh;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  background: black;

  ${MEDIA_QUERY_DESKTOP} {
    margin: 0 10vw;
    filter: drop-shadow(0px 26px 40px rgba(0, 0, 0, 0.2));
    background: #C4C4C4;
    border-radius: 16px;
  }
`

// const Placeholder = styled.div`
//   width: 100%;
//   height: 100%;
//   background-image: url('/images/vpreview5.jpg');
//   background-size: cover;
//   background-position: center;
// `

const Video = styled.video`
  object-fit: cover;
  object-position: center;
  background: black;
`

const VIDEO_SRC = isMobile
  ? '/video/VOID_PREVIEW.mp4'
  : '/video/VOID_PREVIEW_HQ.mp4'

const Subtitle = styled(TitleFour)`
  margin-top: 1rem;
`

const VideoBackground = styled.div`
  display: none;
  position: absolute;
  right: 0;
  bottom: -10vh;
  left: 0;

  ${MEDIA_QUERY_DESKTOP} {
    display: block;
  }

  svg {
    width: 100%;
    height: auto;
  }
`

const Void = () => {
  return (
    <>
      <Container>
        <Section>
          <Title>
            Create audiovisual masterpieces
          </Title>
          <Subtitle>
            Open the door to an undiscovered art form
          </Subtitle>
          <VoidTiles />
          <Footer>
            <Link
              href={PROJECTS_CREATE_SCENE + '?new=true'}
              passHref
            >
              <a
                target='_blank'
                rel='noreferrer'
              >
                <ButtonPrimary>
                  Try VOID in sandbox mode
                </ButtonPrimary>
              </a>
            </Link>
            <FootNote>
              You can try the demo version of <span>VOID</span> before purchasing an Object.
            </FootNote>
          </Footer>
        </Section>
      </Container>
      <VideoContainer>
        <VideoBackground>
          <svg
            width='100%'
            height='100%'
            viewBox='0 0 1436 400'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1436 601H0V260.376L718.25 0L1436 260.376V601Z'
              fill='#8E0AFF'
            />
          </svg>
        </VideoBackground>
        <VideoWrapper>
          <Video
            loop
            playsInline
            autoPlay
            muted
            src={VIDEO_SRC}
            width='100%'
            height='100%'
          />
        </VideoWrapper>
      </VideoContainer>
    </>
  )
}

export default Void
